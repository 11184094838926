<template>
  <head-panel>
    <template v-slot:body>Threads</template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item ">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="helpdesk"
        />
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Description</th>
        <th scope="col">Status</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item" :class="item.hasNewMessages ? 'with-messages' : ''">
        <td>
          <div class="m-icon">
            <svg v-if="item.hasNewMessages" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
              <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
            </svg>
          </div>
        </td>
        <td v-if="!item.description || item.description.length < 100">{{ item.description }}</td>
        <td v-else>{{ item.description.substring(0, 100) }}...</td>
        <td>{{ item.status }}</td>
        <td class="actions">
          <router-link :to="'/live-support/'+item.id">View</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter
  },
  created() {
      this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  computed: {},
  data() {
    return {
      items: [],
      types: [],
      statuses: [],
      categories: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/manage/live?" + new URLSearchParams(this.$route.query).toString())
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
  }
};
</script>

<style scoped>

.with-messages td{
  background-color: #f6fdf3;
}

.m-icon{
  width: 20px;
  margin-right: 10px;
  float: left;
}

.m-icon svg{
  color: red;
}

</style>

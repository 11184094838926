<template>
  <head-panel>
    <template v-slot:body>Pages
      <router-link :to="'/content/'+$route.params.tag+'/create'" class="btn btn-primary btn-sm btn-create ms-2">
        Create
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Search"
            name="search"
            path="content"
            v-model="search"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Name</th>
        <th scope="col">Status</th>
        <th scope="col">Url</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="content in contents" :key="content">
        <tr class="menu">
          <td>{{ content.id }}</td>
          <td>{{ content.name }}</td>
          <td>{{ content.status }}</td>
          <td>{{ content.url }}</td>
          <td class="actions">
            <router-link :to="'/content/'+$route.params.tag+'/'+content.id" v-if="content.editable">Ред.</router-link>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination";
import Tab from "./Tab.vue";

export default {
  name: "Menu",
  components: {
    HeadPanel, SearchFilter, ConfirmDialogue, Pagination, Tab
  },
  created() {
    this.fetch();
  },
  watch: {
    $route() {

      this.fetch();
    },
  },
  data() {
    return {
      search: null,
      contents: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {

      const query = new URLSearchParams();

      if (this.$route.params.tag)
        query.append("tags", this.$route.params.tag)

      if (this.$route.query.page)
        query.append('page', this.$route.query.page);

      Http
          .get(process.env.VUE_APP_CONTENT_SERVICE + '/manage/page?' + query.toString())
          .then((res) => {
            this.contents = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
  }
};
</script>

<style scoped>

</style>

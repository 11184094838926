<template>
  <head-panel>
    <template v-slot:body>
      Thread

      <a @click="close(thread)" v-if="thread && thread.status === 'ACTIVE'"
         class="btn btn-danger btn-sm btn-remove float-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon"
             viewBox="0 0 16 16">
          <path
              d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z"/>
          <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg>
        Close
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="thread">

    <div class="mb-4 mt-4">

      <div>
        <strong>ID:</strong> {{ thread.initiator.id }}<br/>
        <strong>Name:</strong> {{ thread.initiator.name }}<br/>
        <strong>Created:</strong> {{ thread.updatedAt }}<br/>
        <strong>Description:</strong> <br/>{{ thread.description }}<br/>
      </div>

      <div v-if="thread.status !== 'ACTIVE'" class="closed">
        Thread closed at: {{ thread.updatedAt }}. Only user can start thread again.
      </div>

    </div>

    <hr/>

    <div class="messages">

      <div v-for="message in messages" :key="message"
           :class="(thread.initiator.id === message.user.id ? 'lt' : 'rt') + (message.sent === false ? ' fail' : '') + (retryingId === message.id ? ' retry' : '')">
        <div><strong>{{ message.user.name }}</strong> {{ message.text }}</div>

        <svg v-on:click="retry(message)" v-if="message.sent === false" xmlns="http://www.w3.org/2000/svg" width="16"
             height="16" fill="currentColor"
             class="bi bi-arrow-repeat " viewBox="0 0 16 16">
          <path
              d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
          <path fill-rule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
        </svg>

      </div>

    </div>

    <hr/>

    <form v-on:submit="send" v-if="thread.status === 'ACTIVE'">

      <div class="row mb-4">
        <div class="col-lg-12">
          <textarea class="form-control" v-model="form.text"></textarea>
        </div>
        <div class="col-lg-12 d-grid gap-2 mt-2">
          <button type="submit" class="btn btn-primary" :disabled="isSending || !form.text || form.text.length < 2">
            Send
          </button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import {useVuelidate} from "@vuelidate/core";
import {maxLength, minLength, required} from "@vuelidate/validators";

export default {
  name: "LiveSupportThreadView",
  components: {
    HeadPanel, ConfirmDialogue
  },
  created() {
    this.fetch();
    this.fetchMessages();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        text: {
          required,
          maxLength: maxLength(5000),
          minLength: minLength(2),
        }
      },
    };
  },
  data() {
    return {
      thread: null,
      messages: [],
      form: {
        text: null,
      },
      isSending: false,
      retryingId: null
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/manage/live/" + this.$route.params.id)
          .then((res) => this.thread = res.content);
    },
    fetchMessages: function () {
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/manage/live/" + this.$route.params.id + "/messages")
          .then((res) => {
            this.messages = res.content;
          });
    },

    send: async function () {

      this.isSending = true;


      const result = await this.v$.$validate()

      if (!result) return

      const data = {
        text: this.form.text
      };

      Http
          .post(process.env.VUE_APP_HELP_SERVICE + "/manage/live/" + this.$route.params.id + "/send", data)
          .then(() => {
            this.form.text = null;
            this.fetchMessages();
            this.isSending = false;
          })
          .catch(() => {
            this.isSending = false;
          });

      return false;
    },
    retry: function (message) {

      if (this.retryingId)
        return false;

      this.retryingId = message.id;

      Http
          .post(process.env.VUE_APP_HELP_SERVICE + "/manage/live/" + this.$route.params.id + "/retry/" + message.id)
          .then(() => {
            this.fetchMessages();
            this.retryingId = null;
          })
          .catch(() => this.retryingId = null);
    },
    close: function () {

      this.$refs.confirmDialogue.show({
        title: 'Close',
        message: 'Do you really want to close a thread? Only the user can start thread again.',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .post(process.env.VUE_APP_HELP_SERVICE + "/manage/live/" + this.$route.params.id + "/close")
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

.closed {
  background-color: #fad3d3;
  font-size: 14px;
  padding: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-top: 10px;
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.messages > div {
  max-width: 70%;
}

.messages div div {
  font-size: 14px;
  padding: 6px;
  border-radius: 6px;
  word-wrap: break-word;
  display: inline-block;
}

.messages div svg {
  cursor: pointer;
}

.messages div div strong {
  display: block;
}

.messages div.lt {
  align-self: flex-start;
}

.messages div.lt div {
  background-color: #d4edda;
  color: #000000;
}

.messages div.rt {
  align-self: flex-end;
}

.messages div.rt div {
  background-color: #d1ecf1;
  color: #000000;
}

.messages div.fail div {
  background-color: #fad3d3;
  color: #0c5460;
  margin-right: 10px;
}

.retry svg {
  display: inline-block;
  animation: rotateAnimation 2s linear infinite;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>

<template>
  <head-panel>
    <template v-slot:body>Tickets</template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item ">
        <SearchFilter
            label="Search..."
            name="search"
            path="helpdesk"
        />
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Subject</th>
        <th scope="col">Type</th>
        <th scope="col">Status</th>
        <th scope="col">Category</th>
        <th scope="col">Comment</th>
        <th scope="col">Created</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.subject }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.status }}</td>
        <td>{{ item.category }}</td>
        <td v-if="!item.text || item.text.length < 100">{{ item.text }}</td>
        <td v-else>{{ item.text.substring(0, 100) }}...</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/helpdesk/'+item.id">View</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter
  },
  created() {
      this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  computed: {},
  data() {
    return {
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/manage/ticket?" + new URLSearchParams(this.$route.query).toString())
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
  }
};
</script>

<style scoped>

</style>

<template>
  <head-panel>
    <template v-slot:body>Review

    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Search external"
            name="search"
            path="/review/manage"
            v-model="search"
        />
      </div>
      <div class="item">
        <DropdownFilter
            label="Status"
            name="moderate"
            path="/review/manage"
            :items="statuses"
        />
      </div>
    </div>
    <hr/>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">External</th>
        <th scope="col">Author</th>
        <th scope="col">Status</th>
        <th scope="col">Comment</th>
        <th scope="col">Created</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in reviews" :key="item">
        <tr class="menu">
          <td>{{ item.subject.external }}</td>
          <td>{{ item.author.name }} {{ item.anonymous ? '(' + item.anonymous.name + ')' : '' }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.comment ? item.comment.substring(0, 30) + '...' : '' }}</td>
          <td>{{ $filters.dateTime(item.createdAt) }}</td>
          <td class="actions">
            <router-link :to="'/review/manage/'+item.id">Edit</router-link>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>

</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import Tab from "../Tab.vue";
import DropdownFilter from "@/components/DropdownFilter.vue";
import SearchFilter from "../../../components/SearchFilter";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Menu",
  components: {
    Pagination,
    DropdownFilter,
    HeadPanel, Tab, SearchFilter
  },
  created() {
    this.fetch();
  },
  watch: {
    $route() {
      this.fetch();
    },
  },
  computed: {
    statuses() {
      return [
        {value: 'APPROVE', label: 'APPROVE'},
        {value: 'AUTO_APPROVE', label: 'AUTO_APPROVE'},
        {value: 'NEED_MODERATE', label: 'NEED_MODERATE'},
      ];
    },
  },
  data() {
    return {
      search: null,
      reviews: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {

      const query = {};

      if (this.$route.query.search)
        query.eid = this.$route.query.search;

      if (this.$route.query.moderate)
        query.moderate = this.$route.query.moderate;

      if (this.$route.query.page)
        query.page = this.$route.query.page;

      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + '/manage/review?' + new URLSearchParams(query).toString())
          .then((res) => {
            this.reviews = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
  }
};
</script>

<style scoped>

</style>

<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link class="nav-link" :to="'/review/manage'"  v-if="this.$session.can('owner', 'review_view')"
                   v-bind:class="$route.path.startsWith('/review/manage')?'active':''">Review
      </router-link>
    </li>
<!--    <li class="nav-item">-->
<!--      <router-link class="nav-link" :to="'/review/setting'"  v-if="this.$session.can('owner', 'review_config')"-->
<!--                   v-bind:class="$route.path.startsWith('/review/setting')?'active':''">Налаштування-->
<!--      </router-link>-->
<!--    </li>-->
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
    full: Boolean
  },
}
</script>

<style scoped>

</style>

<template>
  <head-panel>
    <template v-slot:body>
      Запити клієнтів
    </template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="ticket">

    <Alert ref="alert"/>

    <div class="row  mb-4">
      <div class="col-xl-3">Date</div>
      <div class="col-lg-8 date">
        <input type="text" class="form-control" v-bind:value="$filters.date(ticket.createdAt, 'DD.MM.YYYY')" disabled>
        <input type="text" class="form-control" v-bind:value="$filters.hour(ticket.createdAt)" disabled>
        <span>:</span>
        <input type="text" class="form-control" v-bind:value="$filters.minute(ticket.createdAt)" disabled>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-lg-5">
        <div class="card">
          <div class="card-header">
            Contacts
          </div>
          <div class="card-body">
            <h5 class="card-title" v-if="ticket.name">{{ ticket.name }}</h5>
            <p class="card-text" v-if="ticket.phone">{{ ticket.phone }}</p>
            <p class="card-text" v-if="ticket.email">{{ ticket.email }}</p>
            <p class="card-text" v-if="!ticket.name && !ticket.phone && !ticket.email">Відсутні</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" v-if="ticket.contacts">
      <div class="col-xl-3"></div>
      <div class="col-lg-5">
        <div class="card">
          <div class="card-header">
            Aditional contacts
          </div>
          <div class="card-body">
            <p class="card-text" v-for="(item, key) in ticket.contacts" :key="item"><b>{{ key }}</b>: {{ item }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" v-if="ticket">
      <div class="col-xl-3"></div>
      <div class="col-lg-5">
        <div class="card">
          <div class="card-header">
            Request
          </div>
          <div class="card-body">
            <h5 class="card-title">{{ ticket.subject }}</h5>
            <p class="card-text"> {{ ticket.text }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" v-if="ticket.data">
      <div class="col-xl-3"></div>
      <div class="col-lg-5">
        <div class="card">
          <div class="card-header">
            Data
          </div>
          <div class="card-body">
            <p class="card-text" v-for="(item, key) in ticket.data" :key="item"><b>{{ key }}</b>: {{ item }}</p>
          </div>
        </div>
      </div>
    </div>


    <hr class="mb-4">

    <div class="row  mb-4">
      <div class="col-xl-3">Тип</div>
      <div class="col-lg-8">
        <VocabularySelect alias="ticket:type" v-model="form.type" :confirmation="true" @change="update"/>
      </div>
    </div>

    <div class="row  mb-4">
      <div class="col-xl-3">Status</div>
      <div class="col-lg-8">
        <VocabularySelect alias="ticket:status" v-model="form.status" :confirmation="true" @change="update"/>
      </div>
    </div>


    <div class="row  mb-4" v-if="form.category">
      <div class="col-xl-3">Category</div>
      <div class="col-lg-8">
        <VocabularySelect alias="ticket:category" v-model="form.category" @change="update"/>
      </div>
    </div>



  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import VocabularySelect from "../../components/VocabularySelect";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";
import Alert from "../../components/Alert.vue";

export default {
  name: "EditTicket",
  components: {
    HeadPanel, ConfirmDialogue, VocabularySelect, Alert
  },
  created() {
    this.fetch();
  },
  watch: {
    'form.category': function (n, o) {
      console.log(n, o)
    },
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        status: {
          required
        },
        category: {
          required
        },
        comment: {
          required,
          maxLength: maxLength(5000)
        }
      },
    };
  },
  data() {
    return {
      ticket: null,
      activities: [],
      categories: [],
      form: {
        type: null,
        status: null,
        category: null,
        comment: null,
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/manage/ticket/" + this.$route.params.id)
          .then((res) => {
            this.ticket = res.content;
            this.form = {
              type: this.ticket.type,
              status: this.ticket.status,
              category: this.ticket.category,
              comment: null,
            };
          });
    },
    update: async function () {

      const data = {
        type: this.form.type,
        status: this.form.status,
        category: this.form.category,
        comment: this.form.comment
      };

      Http
          .post(process.env.VUE_APP_HELP_SERVICE + "/manage/ticket/" + this.ticket.id, data)
          .then(() => {
            this.fetch();
            this.$refs.alert.success('Success');
          })
    }
  }
};
</script>

<style scoped>

.wrapper input, .wrapper textarea {
  background-color: #fff;
  color: #696868;
  font-size: 13px;
}

.wrapper select, .wrapper input {
  max-width: 500px;
}

.wrapper textarea {
  max-width: 500px;
  min-height: 100px;
}

.date {
  display: flex;
}

.date span {
  margin-left: 6px;
  margin-right: 6px;
  padding-top: 6px;
}

.date input {
  width: 40px;
  justify-content: center;
  align-items: center;
}

.date input:first-child {
  width: 100px;
  margin-right: 14px;
}

table.products td {
  vertical-align: middle;
}

textarea.form-control {
  height: 100px;
}

</style>

<template>

      <template v-for="group in items" :key="group">
        <hr/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">{{ group.name }}</div>
          <div class="col-lg-8">

            <div class="form-check form-check-inline" v-for="item in group.items" :key="item">
              <input class="form-check-input" type="checkbox" v-bind:id="item.value"
                     v-model="form.permission[item.value]" :name="item.value">
              <label class="form-check-label" v-bind:for="item.value">{{ item.label }}</label>
            </div>

          </div>
        </div>
      </template>

</template>

<script>
import Http from "../../lib/Http";

export default {
  name: "marketPermissions",
  props: {
    manager: Object
  },
  created() {
    this.form.permission = this.manager.permissions.reduce(function (result, key) {
      result[key] = true;
      return result
    }, {});
  },
  computed: {
    items: function () {
      return [
        {
          name: 'Review',
          items: [
            {label: 'Manage', value: `review:manage`},
          ]
        },
        {
          name: 'Content',
          items: [
            {label: 'Manage', value: 'content:page:manage'},
          ]
        },
        {
          name: 'Client support',
          items: [
            {label: 'Live support manage', value: `helpdesk:live_support:manage`},
            {label: 'Ticket manage', value: `helpdesk:ticket:manage`},
          ]
        },
        {
          name: 'Orders',
          items: [
            {label: 'View', value: `order:view`},
            {label: 'Manage', value: `order:manage`},
          ]
        },
        {
          name: 'Clients',
          items: [
            {label: 'View', value: `client:view`},
            {label: 'Clients', value: `client:manage`},
          ]
        },
        {
          name: 'Import',
          items: [
            {label: 'Manage', value: `import:manage`},
            {label: 'Uploads', value: `import:upload`},
            {label: 'Manage source', value: `import:source:manage`},
            {label: 'Currency manage', value: `currency:manage`},
          ]
        },
        {
          name: 'Products',
          items: [
            {label: 'View', value: `product:view`},
            {label: 'Manage', value: `product:manage`},
          ]
        },
        {
          name: 'Promo',
          items: [
            {label: 'View', value: `promo:code:view`},
            {label: 'Manage', value: `promo:code:manage`},
          ]
        },
        {
          name: 'Menu',
          items: [
            {label: 'Manage', value: 'menu:manage'},
          ]
        },
        {
          name: 'Plaint',
          items: [
            {label: 'Manage', value: 'catalog:manage'},
          ]
        },
        {
          name: 'Category',
          items: [
            {label: 'Manage', value: 'category:manage'},
          ]
        },
        {
          name: 'Brand',
          items: [
            {label: 'Manage', value: 'brand:manage'},
          ]
        },
        {
          name: 'Vocabulary',
          items: [
            {label: 'Vocabulary manage', value: 'tag:vocabulary:manage'},
            {label: 'Tag manage', value: 'tag:manage'},
          ]
        },
        {
          name: 'Ads',
          items: [
            {label: 'Banner manage', value: 'ads:banner:manage'},
          ]
        },
        {
          name: 'Client loyalty',
          items: [
            {label: 'Manage', value: `loyalty:manage`},
          ]
        },
        {
          name: 'SEO',
          items: [
            {label: 'Manage', value: 'seo:manage'},
          ]
        },
        {
          name: 'Settings',
          items: [
            {label: 'Manage', value: 'setting:manage'},
          ]
        }
      ];
    }
  },
  data() {
    return {
      form: {
        permission: []
      }
    }
  },
  methods: {
    submit: async  function () {
      const permissions = [];

      Object.entries(this.form.permission).forEach(item => {
        const [k, v] = item;
        if (v)
          permissions.push(k);
      });

      Http
          .put(process.env.VUE_APP_CRM_SERVICE + "/manager/" + this.manager.id + "/permission", permissions)
          .then(() => {
            this.$refs.alert.success('Success');
          });
    }
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>

<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link class="nav-link" :to="'/content/page'"  v-if="this.$session.can('owner', 'page_manage')"
                   v-bind:class="$route.path.startsWith('/content/page')?'active':''">Pages
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/content/news'"  v-if="this.$session.can('owner', 'page_manage')"
                   v-bind:class="$route.path.startsWith('/content/news')?'active':''">News
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/content/action'"  v-if="this.$session.can('owner', 'page_manage')"
                   v-bind:class="$route.path.startsWith('/content/action')?'active':''">Actions
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/content/blog'"  v-if="this.$session.can('owner', 'page_manage')"
                   v-bind:class="$route.path.startsWith('/content/blog')?'active':''">Blogs
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
    full: Boolean
  },
}
</script>

<style scoped>

</style>

<template>
  <head-panel>
    <template v-slot:body>Market</template>
  </head-panel>

  <div class="wrapper ps-4 pe-4">

    <div class="today p-3">
      <div class="row">
        <div class="col-3">
          <strong> Today, <br/>{{ currenDateName }}</strong>
        </div>
        <div class="col-3">
          <strong>{{ orderSummary.dayOrdersCount }}<span>today</span></strong>
          <label>{{ $filters.morph(orderSummary.dayOrdersCount, ['замовленя', 'замовленя', 'замовлень']) }}</label>
        </div>
        <div class="col-3">
          <strong>{{ orderSummary.newOrderCount }}</strong>
          <label>wait <br/>orders</label>
        </div>
        <div class="col-3">
          <strong>{{ ticketSummary.open }}</strong>
          <label>open <br/>tickets</label>
        </div>
        <div class="col-3">

        </div>
        <div class="col-3">
          <strong>{{ liveSupportSummary.open }}</strong>
          <label>open <br/>threads</label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" v-model="period" value="DAY" name="btnradio" id="btnradio1"
                 autocomplete="off" checked>
          <label class="btn btn-outline-primary" for="btnradio1">День</label>

          <input type="radio" class="btn-check" v-model="period" value="WEEK" name="btnradio" id="btnradio2"
                 autocomplete="off">
          <label class="btn btn-outline-primary" for="btnradio2">Тиждень</label>

          <input type="radio" class="btn-check" v-model="period" value="MONTH" name="btnradio" id="btnradio3"
                 autocomplete="off">
          <label class="btn btn-outline-primary" for="btnradio3">Місяць</label>
        </div>
      </div>
      <div class="col-auto">
        <DateRangeFilter @onChange="changeDateRange" v-model="dateRange" :updateRoute="true" path="/"/>
      </div>
    </div>


    <div style="height: 300px;">
      <v-chart class="chart" :option="ordersChartData"/>
    </div>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import DateRangeFilter from "../../components/DateRangeFilter";
import moment from 'moment'
import HeadPanel from "../../components/HeadPanel";

export default {
  name: "Index",
  components: {
    DateRangeFilter,
    HeadPanel
  },
  created() {

    if (this.$session.can('owner', 'order_read'))
      this.fetchOrderSummary();

    if (this.$session.has('helpdesk')) {
      this.fetchTicketSummary();
      this.fetchLiveSupportSummary()
    }

    if (this.$session.can('owner', 'order_read'))
      this.fetchOrderStat();
  },
  watch: {
    period() {
      this.fetchOrderStat();
    },
  },
  computed: {
    currenDateName: function () {
      const date = new Date();
      return date.getUTCDate() + ' ' + date.toMonthString();
    }
  },
  data() {
    return {
      dateRange: this._defaultDatePeriod(),
      period: 'DAY',
      orderSummary: {
        dayOrdersCount: 0,
        newOrderCount: 0,
      },
      ticketSummary: {
        open: 0
      },
      liveSupportSummary: {
        open: 0
      },
      ordersChartData: null
    }
  },
  methods: {
    changeDateRange: function (range) {
      this.dateRange = range;
      this.fetchOrderStat();
    },
    fetchOrderSummary: function () {
      Http.get(process.env.VUE_APP_CRM_SERVICE + '/order/analytics/summary')
          .then(res => {
            this.orderSummary = res.content;
          });
    },
    fetchTicketSummary: function () {
      Http.get(process.env.VUE_APP_HELP_SERVICE + '/manage/ticket/analytics')
          .then(res => {
            this.ticketSummary = res.content;
          });
    },
    fetchLiveSupportSummary: function () {
      Http.get(process.env.VUE_APP_HELP_SERVICE + '/manage/live/analytics')
          .then(res => {
            this.liveSupportSummary = res.content;
          });
    },
    fetchOrderStat: function () {
      const from = this.dateRange[0];
      const to = this.dateRange[1];
      Http.get(process.env.VUE_APP_CRM_SERVICE + '/order/analytics?period=' + this.period + '&from=' + from + '&to=' + to)
          .then(res => {
            this.ordersChartData = this._ordersChartData(res.content);
          });
    },
    _defaultDatePeriod: function () {

      if (this.$route.query.from && this.$route.query.to)
        return [this.$route.query.from, this.$route.query.to]

      return [moment().subtract(1, 'month').format("YYYY-MM-DD"), moment().add(1, 'day').format("YYYY-MM-DD")];
    },
    _ordersChartData: function (data) {


      const xAxisData = [];
      const seriesLineData = [];
      for (let item of data) {
        xAxisData.push(item.date);
        seriesLineData.push(item.count);
      }

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // Try 'horizontal'
          icon: 'pin',
          orient: 'horizontal',
          right: 10,
          top: 'center'
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          // width: '100%',
          containLabel: true
        },
        xAxis: [
          {
            // interval: 100,
            // min: 5,
            // max: 20,
            type: 'category',
            boundaryGap: false,
            data: xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value',
            // splitLine: {
            //   show: false
            // }
          },
        ],
        series: [
          {
            // name: 'замовлення',
            type: 'line',
            symbolSize: 8,
            lineStyle: {
              color: '#248afd',
            },
            itemStyle: {
              color: '#248afd',//'#f5803e',
            },
            areaStyle: {
              opacity: 0.1,
              color: '#248afd'
            },
            data: seriesLineData
          },
        ]
      };
    }
  }
};
</script>

<style scoped>

.today {
  background: rgba(47, 126, 216, .05);
  border: 1px solid rgba(47, 126, 216, .4);
  border-radius: 5px;
}

.today strong {
  line-height: 1.3;
  font-size: 18px;
}

.today strong span {
  font-size: 11px;
  color: #cccccc;
  font-weight: normal;
  margin-left: 4px;
  margin-top: -2px;
  position: absolute;
}

.today label {
  display: block;
  font-size: 12px;
}

</style>

<template>
  <head-panel>
    <template v-slot:body>
      Відгук <span v-if="review"> {{ review.uuid }}</span>


      <router-link :to=" '/review/manage'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>


      <a @click="remove" v-if="review"
         class="btn btn-danger btn-sm btn-remove float-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити відгук
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Subject</div>
        <div class="col-lg-8">
          <input class="form-control" v-model="form.subject" :class="{ 'is-invalid' : v$.form.subject.$error}" :disabled="review && review.parentId"/>
          <div class="invalid-feedback" v-for="error of v$.form.subject.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">External</div>
        <div class="col-lg-8">
          <input class="form-control" v-model="form.external" :class="{ 'is-invalid' : v$.form.external.$error}" :disabled="review && review.parentId"/>
          <div class="invalid-feedback" v-for="error of v$.form.external.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Status</div>
        <div class="col-lg-3">
          <input class="form-control" v-model="form.status" disabled />
        </div>
        <div class="col-lg-3">
          <a class="btn btn-primary"  @click="approve" v-if="form.status && form.status !== 'APPROVE'">Approve</a>
        </div>
      </div>



      <!--      <div class="row ms-2 mb-4">-->
      <!--        <div class="col-xl-3">Author</div>-->
      <!--        <div class="col-lg-8">-->
      <!--          <Multiselect-->
      <!--              v-model="form.author"-->
      <!--              :options="authors"-->
      <!--              :class="{ 'is-invalid' : v$.form.author.id.$error}"-->
      <!--              :multiple="false"-->
      <!--              :searchable="true"-->
      <!--              @search-change="findAuthor"-->
      <!--              placeholder="Пошук автора за ім`ям чи eid"-->
      <!--              selectLabel=""-->
      <!--              label="label"-->
      <!--              track-by="aid"-->
      <!--          >-->
      <!--            <template v-slot:option="{ option }">-->
      <!--              <span class="option__name">{{ option.label }}</span>-->
      <!--              <span class="option__path"> ({{ option.id }})</span>-->
      <!--            </template>-->
      <!--            <template #noOptions>-->
      <!--              Пошук авторів...-->
      <!--            </template>-->
      <!--            <template #noResult>-->
      <!--              Авторів не знайдено!-->
      <!--            </template>-->
      <!--          </Multiselect>-->
      <!--          <div class="invalid-feedback" v-for="error of v$.form.author.id.$errors" :key="error.$uid">-->
      <!--            {{ error.$message }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Name(no required)</div>
        <div class="col-lg-8">
          <input class="form-control" v-model="form.anonymous.name"
                 :class="{ 'is-invalid' : v$.form.anonymous.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.anonymous.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Rate</div>
        <div class="col-lg-8">
          <select class="form-select" v-model="form.rate" :class="{ 'is-invalid' : v$.form.rate.$error}" :disabled="review && review.parentId">
            <option selected value="null">-- select --</option>
            <option v-for="item in rates" :key="item" v-bind:value="item.value">{{ item.label }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.rate.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Comment</div>
        <div class="col-lg-8">
          <textarea class="form-control" v-model="form.comment" :class="{ 'is-invalid' : v$.form.comment.$error}" />
          <div class="invalid-feedback" v-for="error of v$.form.comment.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Advantages</div>
        <div class="col-lg-8">
          <textarea class="form-control" v-model="form.advantages"
                    :class="{ 'is-invalid' : v$.form.advantages.$error}" :disabled="review && review.parentId"/>
          <div class="invalid-feedback" v-for="error of v$.form.advantages.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Disadvantages</div>
        <div class="col-lg-8">
          <textarea class="form-control" v-model="form.disadvantages"
                    :class="{ 'is-invalid' : v$.form.disadvantages.$error}" :disabled="review && review.parentId"/>
          <div class="invalid-feedback" v-for="error of v$.form.disadvantages.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Published</div>
        <div class="col-lg-8">
          <VueDatePicker v-model="form.publishedAt" @update:model-value="form.publishedAt" :enable-time-picker="true"
                         :utc="false" :is24="true" format="dd/MM/yyyy HH:mm:ss"></VueDatePicker>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import ConfirmDialogue from '../../../components/ConfirmDialog.vue'
import Alert from "../../../components/Alert.vue";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, minLength, required} from "@vuelidate/validators";
import {debounce} from "@/lib/Util";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "Edit",
  components: {
    VueDatePicker,
    HeadPanel, ConfirmDialogue, Alert
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
    // this.findAuthor('');
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        subject: {
          required,
          maxLength: maxLength(50)
        },
        external: {
          maxLength: maxLength(100),
          pattern: helpers.withMessage('Invalid external format', (v) => {
            if (!v)
              return true;

            return !!v.match("^[A-Za-z0-9\\-_.:]+$")
          })
        },
        author: {
          // id: {
          //   required
          // }
        },
        anonymous: {
          name: {
            minLength: minLength(2),
            maxLength: maxLength(255)
          }
        },
        comment: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(5000)
        },
        advantages: {
          maxLength: maxLength(5000)
        },
        disadvantages: {
          maxLength: maxLength(5000)
        },
        // status: {
        //   required,
        // },
        rate: {}
      }
    };
  },
  computed: {
    moderateStatuses: function () {
      return [
        {'label': 'APPROVE', 'value': 'APPROVE'},
        {'label': 'AUTO_APPROVE', 'value': 'AUTO_APPROVE'},
        {'label': 'NEED_MODERATE', 'value': 'NEED_MODERATE'},
      ];
    },
    rates: function () {
      return [
        {'label': '1', 'value': 1},
        {'label': '2', 'value': 2},
        {'label': '3', 'value': 3},
        {'label': '4', 'value': 4},
        {'label': '5', 'value': 5},
      ];
    }
  },
  data() {
    return {
      review: null,
      authors: [],
      form: {
        subject: null,
        external: null,
        author: null,
        anonymous: {
          name: null
        },
        comment: null,
        advantages: null,
        disadvantages: null,
        rate: null,
        status: 'APPROVE',
        publishedAt: new Date(),
        data: {}
      }
    }
  },
  methods: {
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/manage/review/" + id)
          .then((res) => {
            this.review = res.content;
            this.form = {
              subject: res.content.subject.external,
              external: res.content.external,
              // author: {
              //   id: res.content.author.id,
              //   external: res.content.author.external,
              //   label: res.content.author.name,
              // },
              author: null,
              anonymous: {
                name: res.content.anonymous ? res.content.anonymous.name : null
              },
              comment: res.content.comment,
              advantages: res.content.advantages,
              disadvantages: res.content.disadvantages,
              rate: res.content.rate ? res.content.rate.rating : null,
              status: res.content.status,
              publishedAt: res.content.publishedAt,
              data: res.content.data
            };
          });
    },

    findAuthor(query) {
      debounce(() => {
        Http
            .get(process.env.VUE_APP_REVIEW_SERVICE + "/author?search=" + query.toLowerCase())
            .then((res) => {
              this.authors = res.content.map(e => {
                return {
                  id: e.id,
                  label: e.name,
                  external: e.external,
                }
              });
            });
      }, 300)();
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        subject: this.form.subject,
        author: null,
        anonymous: this.form.anonymous ? {name: this.form.anonymous.name} : null,
        external: this.form.external,
        comment: this.form.comment,
        advantages: this.form.advantages,
        disadvantages: this.form.disadvantages,
        rate: this.form.rate ? {
          rating: this.form.rate
        } : null,
        status: this.form.status,
        publishedAt: this.form.publishedAt && this.form.publishedAt instanceof String ? this.form.publishedAt : this._dateFormat(this.form.publishedAt),
        data: this.form.data
      };

      if (!this.review)
        Http
            .post(process.env.VUE_APP_REVIEW_SERVICE + "/manage/review", data)
            .then((res) => {
              this.fetch(res.content);
              this.$refs.alert.success('Success');
            });
      else
        Http
            .put(process.env.VUE_APP_REVIEW_SERVICE + "/manage/review/" + this.review.id, data)
            .then(() => {
              this.$refs.alert.success('Success');
            });
    },

    approve: function(){
      this.$refs.confirmDialogue.show({
        title: 'Approve',
        message: 'Approve review?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .put(process.env.VUE_APP_REVIEW_SERVICE + "/manage/review/" + this.review.id + "/approve")
              .then(() => {
                this.fetch(this.review.id);
                this.$refs.alert.success('Success');
              });
      })
    },

    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Remove',
        message: 'Remove review?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_REVIEW_SERVICE + "/manage/review/" + this.review.id)
              .then(() => {
                this.$router.replace("/review/manage");
              });
      })
    },
    _dateFormat: function formatDateWithoutZone(date) {

      if (!date || !(date instanceof Date))
        return date;

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style scoped>
.form-body {
  height: 400px;
}
</style>

import Sidebar from '../components/Sidebar.vue'
import RequestList from "@/views/live-support/Index.vue";
import ThreadView from "@/views/live-support/ThreadView.vue";

export default [
    {
        path: "/live-support",
        name: "LiveSupportThreads",
        components: {
            default: RequestList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/live-support/:id",
        name: "LiveSupportThreadView",
        components: {
            default: ThreadView,
            LeftSidebar: Sidebar
        }
    },
];
